body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-primary, .btn-outline-primary {
  background-color: #F6DBD3;
  color: black;
}
.btn-primary:hover {
  background-color: #F6DBD3;
  color: black;
}
.btn-outline-primary {
  background-color: transparent;
  color: black;
}
.btn-outline-primary:hover {
  background-color: #F6DBD3;
  color: black;
}
.rating span {
  /* color: #ffc000; */
  color: black;
}
.customNavbarStyle {
  background-color: #C09695;
  /* background-color: black; */
  color: 'black';
}
.text-white {
  color: white;
}
.site-container {
  min-height: 100vh;
}
main {
  flex: 1;
}
.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product {
  border: 1px #404040 solid;
  margin: 1rem;
}
.product img {
  width: 100%;
  max-width: 400px;
}
.product-info {
  padding: 1rem;
}
.img-large {
  max-width: 100%;
  max-height: 80vh;
}
.img-thumbnail {
  height: 80px;
}
.fixed-image {
  max-height:30rem;
  object-fit: cover; 
  /* object-fit: cover; Maintain aspect ratio and cover entire container */
}
.small-container{
  max-width: 600px;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height:50dvh; /* Adjust the height as needed */
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* Maintain aspect ratio and contain within the container */
}

.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}
.checkout-steps > div.active {
  border-bottom: 0.2rem solid #f08000;
  color: #f08000;
}
.logo-img {
  height: 6vmin;; /* Match the height of the Bag icon */
  width: auto;  /* Keep the aspect ratio */
}

/* Sidebar styles */
.side-navbar {
  position: fixed;
  top: 0;
  left: -250px; /* Initially hidden off-screen */
  height: 100%;
  width: 250px;
  background-color: #C09695;
  padding-top: 20px;
  z-index: 1000;
  transition: left 0.3s ease-in-out;
}

/* Show the sidebar when active */
.active-nav .side-navbar {
  left: 0; /* Slide in from the left */
}

/* Overlay to darken the main content */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
  display: none;
  transition: display 0.3s ease-in-out;
}

.active-nav .overlay {
  display: block; /* Show the overlay when sidebar is active */
}

/* Adjust the site-container when sidebar is active */
.site-container {
  transition: margin-left 0.3s ease-in-out;
}

/* No margin shift needed as sidebar hovers */
